const component = {
    type: 'Highcharts',
    cell: 'gm-nm-pijplijn-totaal',
    connector: {
        id: 'pijplijn_totaal_pool'
    },
    sync: {
        crossfilter: false
    },
    columnAssignment: {
        'vergunningspijplijn': 'y',
        'bouw gestart pijplijn': 'y',
    //     'Woningen waarvoor een vergunning is afgegeven maar nog niet gestart met bouw': 'vergunningspijplijn',
    //     'Vergund en gestart met de bouw': 'bouw_gestart_pijplijn',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Pijplijn woningen totaal'
    },
    chartOptions: {
        chart: {
            type: 'area',
        },
        plotOptions: {
            area: {
                stacking: 'normal',  // Zorgt voor de gestapelde kolommen
            }
        },
        legend: {
            labelFormatter: function (this: Highcharts.Series): string {
                if (this.name === 'bouw gestart pijplijn') {
                    return 'vergunning verleend, bouw gestart';
                }
                else if (this.name === 'vergunningspijplijn') {
                    return 'vergunning verleend, bouw nog niet gestart';
                }
                return this.name;
            },
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y %B}',
                align: 'right',
                rotation: -30
            },
            tickInterval: 6 * 30 * 24 * 3600 * 1000, // 6 maanden in milliseconden
            dateTimeLabelFormats: {
                day: '%e %b %Y',    // Formaat voor dagen (optioneel)
                month: '%b %Y',     // Maand en jaar
                year: '%Y'          // Jaar
            }
        },
        yAxis: {
            min: 0,
            labels: {
                formatter: function(this: { value: number }): string {
                    const value = this.value;
                    if (value < 100000) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                    return Math.round(value / 1000) + 'k';
                }
            }
        },
    },
};

export default component;