export default {
    "gm-nm-aanbod": {
        title: "Actueel nieuwbouw aanbod: Nieuwbouwprojecten aangeboden via Nieuwbouw Nederland",
        credit: "Nieuwbouw Nederland",
        html: "<p>Op deze kaart zie je alle projecten die momenteel via websites van Nieuwbouw Nederland worden aangeboden. Deze projecten bevinden zich in verschillende fases, van eerste melding van het plan tot afgeronde verkoop. \n" +
            "\n" +
            "Niet alle nieuwbouw koopwoningen worden verkocht via websites van Nieuwbouw Nederland. De dekking wisselt sterk per gemeente maar over heel Nederland is ongeveer 2/3e  van het aantal verkochte nieuwbouw koopwoningen aangeboden geweest op een Nieuwbouw Nederland website.</p>"
    },
    "gm-nm-quickfact": {
        title: "Actuele aantallen in de gemeente",
        credit: "Nieuwbouw Nederland",
        html: "<p>Alle projecten met actueel koopaanbod</p>"
    },
    "gm-nm-objecten-per-project": {
        title: "",
        credit: "Nieuwbouw Nederland",
        html: "<p></p>"
    },
    "gm-nm-vraagprijs-per-m2": {
        title: "",
        credit: "Nieuwbouw Nederland",
        html: "<p></p>"
    },
    "gm-nm-bouwvergunningen-naar-opdrachtgever": {
        title: "Aantal afgegeven bouwvergunningen naar opdrachtgever ",
        credit: "CBS",
        html: "<p>Het aantal afgegeven bouwvergunningen geeft een goede indicatie voor de woningbouwproductie de komende twee jaar. Over het algemeen gaat zo’n 90% van de projecten binnen enkele maanden na afgifte van de vergunning in de verkoop of begint de bouw. Deze cijfers zijn gebaseerd op data-aanleveringen door gemeenten naar het CBS. Niet elke gemeente is even snel, het kan dus zijn dat de data voor de ene gemeente recenter is dan voor de andere gemeenten. \n" +
            "  \n" +
            "Overheid of woningcorporatie betreffen in de praktijk bijna altijd (sociale) huurwoningen voor woningcorporaties. \n" +
            "Bouwers voor de markt betreffen zowel huurwoningen voor beleggers als koopwoningen voor eigenaar-bewoners. \n" +
            "Andere particuliere opdrachtgevers betreffen vooral koopwoningen voor particulieren of groepen particulieren. \n" +
            "  \n" +
            "Voor verdere uitleg zie <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83671NED/table?ts=1618925392557' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83671NED/table?ts=1618925392557</a></p>"
    },
    "gm-nm-krapte-indicator": {
        title: "",
        credit: "",
        html: "<p></p>"
    },
    "gm-nm-woningbezit-kaart": {
        title: "Woningbezit naar eigendom",
        credit: "CBS",
        html: "<p>Bij koopwoningen is de eigenaar altijd de eigenaar-bewoner. Beleggers hebben overwegend vrije sector/middeldure huurwoningen, maar kunnen ook sociale huurwoningen in bezit hebben. Corporaties hebben overwegend sociale huurwoningen in bezit, maar, kunnen ook vrije sector/middeldure huurwoningen in bezit hebben.</p>"
    },
    "gm-nm-woningvoorraad-eindstand": {
        title: "Eindstand woningvoorraad",
        credit: "CBS",
        html: "<p>Deze CBS data is gebasseerd op veranderingen in de Basisregistratie Adressen en Gebouwen (BAG). Voor verdere uitleg zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/81955NED/table?ts=1741794901945'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/81955NED/table?ts=1741794901945</a></p>"
    },
    "gm-nm-woningvoorraad-mutaties": {
        title: "Eindstand woningvoorraad",
        credit: "CBS",
        html: "<p>Deze CBS data is gebasseerd op veranderingen in de Basisregistratie Adressen en Gebouwen (BAG). De categorie 'overige toevoeging' bestaat vooral uit splitsingen, verbouwingen en verandering van gebruiksfunctie. De categorie 'overige ontrekking' bestaat vooral uit samenvoegingen en veranderingen van gebruikfunctie. Voor verdere uitleg zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/81955NED/table?ts=1741794901945'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/81955NED/table?ts=1741794901945</a></p>"
    },
    "gm-nm-pijplijn-totaal": {
        title: "Pijplijn woningen totaal",
        credit: "CBS",
        html: "<p>Voor elke maand is inzichtelijk gemaakt hoeveel verblijfsobjecten in de pijplijn zitten om gebouwd te worden: deze hebben ofwel een verleende bouwvergunning, of er is een melding gemaakt van bouw gestart. De pijplijn wordt afgeleid uit de statussen van verblijfsobjecten en panden in de Basisregistratie Adressen en Gebouwen (BAG). Daarbij is de verleende bouwvergunning nog niet onherroepelijk, vertraging kan dus ontstaan bijvoorbeeld door bezwaarmakers. Voor zover wij weten is er geen overzicht van alle bezwaarprocedures, wel kunnen deze per zaak worden opgezocht via <a href='https://www.raadvanstate.nl/uitspraken/'>https://www.raadvanstate.nl/uitspraken \\n\" +" +
            "  \n" +
            "Voor verdere uitleg zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/82211NED/table?ts=1714378031698'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/82211NED/table?ts=1714378031698</a></p>"
    },
    "gm-nm-pijplijn-kort": {
        title: "Vertraagde projecten",
        credit: "CBS",
        html: "<p>Voor verdere uitleg zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/82211NED/table?ts=1714378031698'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/82211NED/table?ts=1714378031698</a></p>"
    },
    "gm-nm-pijplijn-lang": {
        title: "Probleemprojecten",
        credit: "CBS",
        html: "<p>Voor verdere uitleg zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/82211NED/table?ts=1714378031698'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/82211NED/table?ts=1714378031698</a></p>"
    },
    "gm-nm-woningbezit-grafiek": {
        title: "Woningbezit naar eigendom, gevegens zijn van hetzelfde jaar als kaart woningbezit (koopwoningen)",
        credit: "CBS",
        html: "<p></p>"
    },
    "gm-nm-woningvoorraad": {
        title: "Voorraad bestaande woningen naar oppervlakte",
        credit: "CBS",
        html: "<p>Voor verdere uitleg zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83704NED/table?ts=1618925715582'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83704NED/table?ts=1618925715582</a></p>"
    },
    "gm-nm-woz-waarde": {
        title: "WOZ-waarde",
        credit: "CBS",
        html: "<p>De WOZ-waarde wordt altijd per 1/1 door de gemeente berekend op basis van enkele referentie transacties. De WOZ waarde kan dus niet gezien worden als een actuele, marktwaarde taxatie. Wel geeft het een goed gevoel bij de waarde van woningen in de buurt afgezet tegen andere buurten in de gemeente. Ook laat de WOZ waarde ontwikkeling goed zien of de woningmarkt in de buurt zich gunstig of ongunstig ontwikkeld.</p>"
    },
    "gm-nm-woz-waarde-buurt": {
        title: "WOZ-waarde Buurt versus landelijk gemiddelde",
        credit: "CBS",
        html: "<p>De WOZ-waarde wordt altijd per 1/1 door de gemeente berekend op basis van enkele referentie transacties. De WOZ waarde kan dus niet gezien worden als een actuele, marktwaarde taxatie. In deze figuur wordt de WOZ waarde  van de buurt afgezet tegen het landelijk gemiddelde. Dit laat zien of het een relatief dure of goedkope buurt is. Ook laat het zien of de buurt zich de afgelopen jaren positiever of negatiever heeft ontwikkeld ten op zichtte van het landelijk gemiddelde, in ieder geval qua WOZ-waarden.</p>"
    }
}