const component = {
    type: 'Highcharts',
    cell: 'gm-nm-woningvoorraad-mutaties', // Hier wordt de cell genoemd, mogelijk aangepast aan je data
    connector: {
        id: 'woningvoorraad_mutaties_pool' // Dit zorgt voor de connectie met je data
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'nieuwbouw': 'nieuwbouw',
        'overige toevoeging': 'overige_toevoeging',
        'sloop': 'sloop',
        'overige onttrekking': 'overige_onttrekking',
        'correctie': 'correctie',
        // 'saldo voorraad': 'saldo_voorraad', // Zorg ervoor dat saldo voorraad wordt meegenomen
        datum: 'x' // x is de tijd/datum voor de x-as
    },
    title: {
        useHTML: true,
        text: 'Mutaties voorraad'
    },
    chartOptions: {
        chart: {
            type: 'column'  // Dit is voor de gestapelde kolommen
        },
        plotOptions: {
            column: {
                stacking: 'normal',  // Zorgt voor de gestapelde kolommen
            },
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y}',
                align: 'right',
                rotation: -30
            }
        },
        yAxis: {
            labels: {
                formatter: function(this: { value: number }): string {
                    const value = this.value;
                    if (value < 100000) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                    return Math.round(value / 1000) + 'k';
                }
            }
        },
        tooltip: {
            shared: true
        },
    }
};

export default component;
