import kaartGemeenteAanbodChart from "./components/kaart-gemeente-aanbod.chart.ts";
import quickfactsChart from "./components/quickfact-1.chart.ts";
import gmNmObjectenPerProjectCharts from "./components/gm-nm-objecten-per-project.charts.ts";
import gmNmBouwvergunningenNaarOpdrachtgeverChart from "./components/gm-nm-bouwvergunningen-naar-opdrachtgever.chart.ts";
import gmNmWoningbezitGrafiekChart from "./components/gm-nm-woningbezit-grafiek.chart.ts";
import gmNmWoningbezitGrafiekFilter from "./components/gm-nm-woningbezit-grafiek.filter.ts";
import gmNmWozWaardeChart from "./components/gm-nm-woz-waarde.chart.ts";
import gmNmPijplijnTotaal from "./components/gm-nm-pijplijn-totaal.chart.ts";
import gmNmPijplijnKort from "./components/gm-nm-pijplijn-kort.chart.ts";
import gmNmPijplijnLang from "./components/gm-nm-pijplijn-lang.chart.ts";
import gmNmWozWaardeBuurtChart from "./components/gm-nm-woz-waarde-buurt.chart.ts";
import gmNmWozWaardeBuurtFilter from "./components/gm-nm-woz-waarde-buurt.filter.ts";
import gmNmWoningvoorraadEindstand from "./components/gm-nm-woningvoorraad-eindstand.chart.ts";
import gmNmWoningvoorraadMutaties from "./components/gm-nm-woningvoorraad-mutaties.chart.ts";
import gmNmWoningvoorraad from "./components/gm-nm-woningvoorraad.chart.ts";
import gmNmWoningvoorraadFilter from "./components/gm-nm-woningvoorraad.filter.ts";
import kaartWoningBezitBuurtChart from "./components/kaart-woning-bezit-buurt.chart.ts";

export const importAll = () => {
    return [
        kaartGemeenteAanbodChart,
        quickfactsChart,
        gmNmObjectenPerProjectCharts,
        gmNmBouwvergunningenNaarOpdrachtgeverChart,
        gmNmWoningbezitGrafiekChart,
        gmNmWoningbezitGrafiekFilter,
        gmNmWozWaardeChart,
        gmNmWozWaardeBuurtChart,
        gmNmWozWaardeBuurtFilter,
        gmNmPijplijnTotaal,
        gmNmPijplijnKort,
        gmNmPijplijnLang,
        gmNmWoningvoorraadEindstand,
        gmNmWoningvoorraadMutaties,
        gmNmWoningvoorraad,
        gmNmWoningvoorraadFilter,
        kaartWoningBezitBuurtChart
    ]
}