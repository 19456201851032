import type { Point } from 'highcharts';

const component = {
    type: 'Highcharts',
    cell: 'gm-nm-woz-waarde',
    connector: {
        id: 'woz_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'woz waarde': 'woz_waarde',
        'nederland gemiddelde': 'nederland_gemiddelde',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'WOZ-waarde'
    },
    chartOptions: {
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y}',
                align: 'right',
                rotation: -30
            }
        },
        yAxis: {
            labels: {
                formatter: function(this: { value: number }): string {
                    const value = this.value;
                    if (value < 100) {
                        return (value * 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                    return Math.round(value) + 'k';
                }
            }
        },
        tooltip: {
            useHTML: true,
            headerFormat: '<b>{point.x:%Y}</b><br/>',
            pointFormatter: function(this: Point): string {
                const value = (this.y || 0) * 1000;
                return this.series.name + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '<br/>';
            }
        }
    }
};

export default component;