import type { Axis, Point } from 'highcharts';

const component = {
    type: 'Highcharts',
    cell: 'gm-nm-woningvoorraad-eindstand',
    connector: {
        id: 'woningvoorraad_eindstand_pool'
    },
    sync: {
        crossfilter: false
    },
    columnAssignment: {
        aantal: 'y',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Eindstand voorraad'
    },
    chartOptions: {
        chart: {
            type: 'area',
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y %B}',
                align: 'right',
                rotation: -30
            },
            tickInterval: 6 * 30 * 24 * 3600 * 1000, // 6 maanden in milliseconden
            dateTimeLabelFormats: {
                day: '%e %b %Y',    // Formaat voor dagen (optioneel)
                month: '%b %Y',     // Maand en jaar
                year: '%Y'          // Jaar
            },
        },
        yAxis: {
            startOnTick: false,
            endOnTick: true,
            minPadding: 0.05,
            labels: {
                formatter: function(this: { value: number }): string {
                    const value = this.value;
                    if (value < 100000) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                    return Math.round(value / 1000) + 'k';
                }
            },
            events: {
                afterSetExtremes: function(this: Axis) {
                    if (this.series?.[0]?.points) {
                        const minValue = Math.min(...this.series[0].points.map((p: Point) => p.y || 0));
                        if (minValue > 0) {
                            this.update({
                                min: Math.floor(minValue * 0.95)
                            }, true);
                        }
                    }
                }
            }
        },
    }
};

export default component;