import type { Point } from 'highcharts';

const component = {
    type: 'Highcharts',
    cell: 'gm-nm-bouwvergunningen-naar-opdrachtgever',
    connector: {
        id: 'bouwvergunningen-naar-opdrachtgever_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'totaal opdrachtgevers': 'y',
        'overheid of woningcorporatie': 'y',
        'bouwers voor de markt': 'y',
        'andere particuliere opdrachtgevers': 'y',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Bouwvergunningen naar opdrachtgever'
    },
    chartOptions: {
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y Q%Q}',
                align: 'right',
                rotation: -30
            }
        },
        yAxis: {
            labels: {
                formatter: function(this: { value: number }): string {
                    const value = this.value;
                    if (value < 100000) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                    return Math.round(value / 1000) + 'k';
                }
            }
        },
        tooltip: {
            useHTML: true,
            headerFormat: '<b>{point.x:%Y Q%Q}</b><br/>',
            pointFormatter: function(this: Point): string {
                const value = this.y || 0;
                return this.series.name + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '<br/>';
            },
            shared: true
        }
    }
};

export default component;