import {Parsers} from "../../utils/parsers.ts";
import {baseUri} from "../../config/config.ts";

export default [
    {
        type: 'JSONAuth',
        id: 'werkloosheid_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/werkloosheid?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },{
        type: 'JSONAuth',
        id: 'opleiding_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/opleiding?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        }
    }, {
        type: 'JSONAuth',
        id: 'opleiding_categorie_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/opleiding-categorie?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            },
            /*dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'buurtcode',
                    minValue: '',
                    maxValue: ''
                }]
            }*/
        }
    },{
        type: 'JSONAuth',
        id: 'bevolkingsgroei_prognose_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/bevolkingsgroei-prognose?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        }
    },{
        type: 'JSONAuth',
        id: 'demografische_kerngetallen_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/demografische-kerngetallen?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        }
    },{
        type: 'JSONAuth',
        id: 'bevolkingsdichtheid_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/bevolkingsdichtheid?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'jaar',
                    minValue: '',
                    maxValue: ''
                }]
            }
        }
    },{
        type: 'JSONAuth',
        id: 'prijscategorie_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/prijscategorie?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        },
    },{
        type: 'JSONAuth',
        id: 'woning_voorkeur_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/woning-voorkeur?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        }
    },{
        type: 'JSONAuth',
        id: 'huur_of_koop_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/huur-of-koop?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        }
    },{
        type: 'JSONAuth',
        id: 'huishouden_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-vnn/huishouden?gmcode=%gmcode%'),
            beforeParse: function(dataset: any){
                return dataset;
            }
        }
    },
];