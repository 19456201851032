const component = {
    type: 'Highcharts',
    cell: 'gm-nm-pijplijn-kort',
    connector: {
        id: 'pijplijn_kort_pool'
    },
    sync: {
        crossfilter: false
    },
    columnAssignment: {
        'bouw gestart pijplijn2': 'y',
        'vergunningspijplijn2': 'y',

    //     'Woningen waarvoor een vergunning is afgegeven maar nog niet gestart met bouw': 'vergunningspijplijn',
    //     'Vergund en gestart met de bouw': 'bouw_gestart_pijplijn',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Vertraagde projecten'
    },
    chartOptions: {
        legend: {
            labelFormatter: function (this: Highcharts.Series): string {
                if (this.name === 'bouw gestart pijplijn2') {
                    return 'Langer dan twee jaar geleden vergunning verleend, bouw inmiddels gestart';
                } else if (this.name === 'vergunningspijplijn2') {
                    return 'Langer dan twee jaar geleden vergunning verleend, bouw nog niet gestart';
                }
                return this.name;
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y %B}',
                align: 'right',
                rotation: -30
            },
            tickInterval: 12 * 30 * 24 * 3600 * 1000, // 12 maanden in milliseconden
            dateTimeLabelFormats: {
                day: '%e %b %Y',
                month: '%Y %B',
                year: '%Y'
            }
        },
        yAxis: {
            min: 0,
            labels: {
                formatter: function(this: { value: number }): string {
                    const value = this.value;
                    if (value < 100000) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                    }
                    return Math.round(value / 1000) + 'k';
                }
            }
        },
    },
};

export default component;